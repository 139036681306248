import { checkCookie } from "utils/cookies";


//const API_BASE_URL = 'http://localhost:9000';
const API_BASE_URL = 'https://api.kincrew.io:9494';


export const Baseurl = API_BASE_URL;
export const CLIENTID = '10042454312-kbc1321h3vvrhs9bjq4pfm8b9tj0b9iu.apps.googleusercontent.com';
export const registerUserService = (request) => {
    // const REGISTER_API_ENDPOINT = 'http://localhost:3000/api/v1/register';

    // const parameters = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(request.user),
    // };

    // return fetch(REGISTER_API_ENDPOINT, parameters)
    //     .then((response) => {
    //         return response.json();
    //     })
    //     .then((json) => {
    //         return json;
    //     });

    // Register
    const credentials = {
        first_name: request.user.first_name,
        last_name: request.user.last_name,
        email: request.user.signup_email,
        pwd: request.user.signup_password,
        provider: "local",
        terms_condition: true,
        news_updates: request.user.news_updates,
        isTrial: request.user.isTrial,
    };


    return makeRequest('POST', '/api/users', credentials)
        .then((response) => {
            return response;
        })
        .then((json) => {
            return json;
        });
};




export const loginUserService = (request) => {
    // const LOGIN_API_ENDPOINT = 'http://localhost:3000/api/v1/login';

    // const parameters = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(request.user),
    // };

    /*return fetch(LOGIN_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });*/

    const credentials = {
        email: request.user.email,
        pwd: request.user.password,
    };

    return makeRequest('POST', '/auth/login', credentials)
        .then((response) => {
            return response;
        })
        .then((json) => {
            return json;
        });
};


// cliffex ----------

// export const addChildren = (data) =>{
//     return makeRequest('POST', '/api/members', data)
//         .then((response) => {
//             return response;
//         })
//         .then((json) => {
//             return json;
//         });
// }


export const makeRequest = (method, url, body, withoutAuthorization) => {
    const request_ulr = API_BASE_URL + url;
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Version: '6.0',
    };
    let access_token = '';
    if(checkCookie() !== null){
        access_token = checkCookie();
    }
    if (access_token && !withoutAuthorization) {
        headers.authorization = 'Bearer ' + access_token;
    }
    const options = { method, headers };
    if (body) {
        options.body = JSON.stringify(body);
    }
    return fetch(request_ulr, options).then((response) => {
        return response.text().then((text) => {
            return text ? JSON.parse(text) : {};
        });
    });
};


export const subscriptionReuqest = (method, url, body,authToken, withoutAuthorization) =>{    
  
    var orgId = "727031825";  
    const headers = {
        Accept: '*/*',        
        Connection: 'keep-alive',
       };

       headers["Accept-Encoding"] = 'gzip, deflate, br';
   
    if (authToken && !withoutAuthorization) {       
        headers["X-com-zoho-subscriptions-organizationid"] = orgId;
        headers["Authorization"] = "Zoho-oauthtoken " + authToken;        

    }
    const options = { method, headers };
    if (body) {
        options.body = JSON.stringify(body);
    }
    return fetch(url, options).then((response) => {
        return response.text().then((text) => {
            return text ? JSON.parse(text) : {};
        });
    }); 
}
